import React from 'react';
import { connect } from 'react-redux';
import AppleSignin from 'react-apple-signin-auth';
import { login, setLoggedIn } from '../actions/login/authssoActions';
import { jwtDecode } from 'jwt-decode';
import Apple from './app-apple.webp'

const AppleAppLogin = ({ login, onClosePopup }) => {

    const handleLoginWithApple = () => {
        const currentUrl = window.location.href;
        const updatedUrl = `${currentUrl}${currentUrl.includes('?') ? '&' : '?'}apple_login=1`;
        window.location.href = `https://sso.fusionkitchen.co.uk/apple_redirect.php?page=${encodeURIComponent(updatedUrl)}`;
    };

    const onSuccess = (res) => {
        console.log(res);
        onClosePopup();
        const decodedToken = jwtDecode(res.authorization.id_token);
        const email = decodedToken.email;
        const firstName = '';
        const lastName = '';
        const postData = {
            verfication_code: res.authorization.code,
            login_type: 4,
            email: email,
            first_name: firstName,
            last_name: lastName,
        };
        console.log(postData);
        login(JSON.stringify(postData));
    };

    const onFailure = (res) => {
        console.log('Login Failed! res:', res);
    };

    return (


        <AppleSignin
            data-Fus="fus-test-appleLoginbtn"
            onClick={handleLoginWithApple}
        >
            <img src={Apple} alt="Apple Icon" />
            contine with Apple
        </AppleSignin>

    );
};

const mapStateToProps = (state) => {
    return {
        isLoggedIn: state.userdata.isLoggedIn,
        userData: state.userdata.userData,
    };
};

const mapDispatchToProps = {
    login,
    setLoggedIn,
};

export default connect(mapStateToProps, mapDispatchToProps)(AppleAppLogin);
